<div *ngIf="business.activeStatus==='Verified'">
  <div class="verig-pjk" *ngIf="isListOpened">
    <span>
      {{ 'Sellers.Dashboard.CompleteContractSteps' | translate }}
    </span>
    <div class="progress-bar mt-3">
      <div class="progress">
        <div class="progress-bar" role="progressbar" [style.width]="calculatePercentage()+'%'"
             aria-valuenow="calculatePercentage()" aria-valuemin="0" aria-valuemax="100">{{calculatePercentage()}}%</div>
      </div>
    </div>
    <div class="mt-3">
      <div class="checkbox-bc mb-2">
        <input type="checkbox" [checked]="business.contractStatus !== 'empty'" [disabled]="business.contractStatus !== 'empty'" [disabled]="true" />
        <a class="btn p-0 text-start ms-2" (click)="openContract()"
           [ngClass]="{'disabled-link': business.contractStatus !== 'empty'}">
          {{ 'Sellers.Dashboard.SubmitContractInformation' | translate }}
        </a>
      </div>
      <div class="checkbox-bc mb-2">
        <input type="checkbox" [checked]="business.contractStatus === 'confirmed'" [disabled]="business.contractStatus === 'confirmed'" [disabled]="true" />
        <a class="btn p-0 text-start ms-2" (click)="openContract()"
           [ngClass]="{'disabled-link': business.contractStatus === 'confirmed'}">
          {{ 'Sellers.Dashboard.ConfirmContractInformation' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
