<form *ngIf="ticketsForm" [formGroup]="ticketsForm" id="ticketsForm">
  <div formArrayName="tickets"> 
        <div class="justify-content-center" *ngFor="let ticket of ticketsForm.get('tickets').controls; let i = index"
            [formGroupName]="i">
            <div class="cardbg-white my-3 relativebody"> 
              <div class="row"> 
          <app-text [fieldObject]="ticketFields.name"
                    [fieldErrors]="formValidatorArray[i].formErrors"
                    [formControlRef]="convertTicketControls(i, ticketFields.name.fieldName)"
                    class="col-md-3 mb-4"
                    id="ticket-name">
          </app-text> 
          <app-number [fieldObject]="ticketFields.price"
                      [fieldErrors]="formValidatorArray[i].formErrors"
                      [formControlRef]="convertTicketControls(i, ticketFields.price.fieldName)"
                      class="ch-12 col-md-3 mb-4"
                      id="ticket-price">
          </app-number>
          <app-ticket-image [fieldObject]="ticketFields.image"
                            [fieldErrors]="formValidatorArray[i].formErrors"
                            [formControlRef]="convertTicketControls(i, ticketFields.image.fieldName)"
                            class="col-md-6 mb-4 dottaedbord"
                            id="ticket-image">
          </app-ticket-image>
          <app-number *ngIf="QuantityDescription.length > 0"
                      [fieldObject]="ticketFields.quantity"
                      [fieldErrors]="formValidatorArray[i].formErrors"
                      [formControlRef]="convertTicketControls(i, ticketFields.quantity.fieldName)"
                      class="ch-12 col-md-3 mb-4"
                      [showIcon]=true
                      id="ticket-quantity">
          </app-number>
          <app-number *ngIf="QuantityDescription.length == 0"
                      [fieldObject]="ticketFields.quantity"
                      [fieldErrors]="formValidatorArray[i].formErrors"
                      [formControlRef]="convertTicketControls(i, ticketFields.quantity.fieldName)"
                      class="ch-12 col-md-3 mb-4"
                      [showIcon]=true
                      id="ticket-quantity">
          </app-number>
          <app-number [fieldObject]="ticketFields.pending_quantity"
                      [fieldErrors]="formValidatorArray[i].formErrors"
                      [formControlRef]="convertTicketControls(i, ticketFields.pending_quantity.fieldName)"
                      class="ch-12 col-md-3 mb-4"
                      [showIcon]=true [readonly]=true
                      [toolTipMsg]="pendigQuantityDescription"
                      id="ticket-quantity">
          </app-number>
          <app-number [fieldObject]="ticketFields.paid_quantity"
                      [fieldErrors]="formValidatorArray[i].formErrors"
                      [formControlRef]="convertTicketControls(i, ticketFields.paid_quantity.fieldName)"
                      class="ch-12 col-md-3 mb-4"
                      [showIcon]=true [readonly]=true
                      [toolTipMsg]="pendigQuantityDescription"
                      id="ticket-quantity">
          </app-number>
          <app-number [fieldObject]="ticketFields.maxQuantity"
                      [fieldErrors]="formValidatorArray[i].formErrors"
                      [formControlRef]="convertTicketControls(i, ticketFields.maxQuantity.fieldName)"
                      class="ch-12 col-md-3 mb-4"
                      id="ticket-max-quantity">
          </app-number>
          <app-text-area [fieldObject]="ticketFields.description"
                        [fieldErrors]="formValidatorArray[i].formErrors"
                        [formControlRef]="convertTicketControls(i, ticketFields.description.fieldName)"
                        class="col-md-6 mb-4"
                        id="ticket-desc">
          </app-text-area>
          <app-date-now [fieldObject]="ticketFields.expiry"
                        [fieldErrors]="formValidatorArray[i].formErrors"
                        [formControlRef]="convertTicketControls(i, ticketFields.expiry.fieldName)"
                        class="col-md-6 mb-4"
                        id="ticket-expiry">
          </app-date-now>
          <app-hidden [fieldObject]="ticketFields.id"
                      [formControlRef]="convertTicketControls(i, ticketFields.id.fieldName)"
                      id="ticket-quantity">
          </app-hidden>
          <mat-icon class="delection delete-icon col-auto text-danger" (click)="deleteTicket(i)">delete</mat-icon>
        </div>
      </div>
    </div>
    <div class="cardbg-white">
        <div class="text-right">
          <button type="button" class="btn btn-success py-1" (click)="addTicketFormGroup()">
            {{ 'Sellers.Products.Add' | translate }}
          </button>
        </div>
    </div>
  </div>

</form>
