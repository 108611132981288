<div mat-dialog-title>
  <div class="d-flex justify-content-end">
    <button type="button" class="btn-close" aria-label="Close" (click)="onNoClick()"></button>
  </div>
</div>

<div mat-dialog-content>
  <img class="img-fluid rounded" style="aspect-ratio: 1/0.5;" [src]="imgPath" alt="" />
</div>

<div mat-dialog-actions>
  <div class="d-flex justify-content-between pt-2">
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="checkbox" name="checkbox" (change)="checkCheckBoxvalue($event)" />
      <label class="form-check-label" for="checkbox">{{ 'Sellers.Dashboard.Do_not_show_again' | translate }}</label>
    </div>
    <button type="button" class="btn btn-primary" (click)="onNoClick()">
      {{ 'Sellers.Dashboard.close' | translate }}
    </button>
  </div>
</div>
