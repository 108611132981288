<h1 mat-dialog-title>Switch to {{ data.businessName }}</h1>

<form mat-dialog-content class="login-form mt-4" *ngIf="loginForm" [formGroup]="loginForm" (submit)="onLogin()"
  novalidate>
  <div class="row">
    <div class="col-lg-12">
      <div class="mb-3">
        <label class="form-label">
          {{ 'Login.Password' | translate }} <span class="text-danger">*</span>
        </label>
        <div class="form-icon position-relative">
          <i data-feaher="key" class="fea icon-sm icons"></i>
          <input id="login-password" formControlName="password" type="password" class="form-control px-2"
            placeholder="Password must have at least 6 characters"
            [ngClass]="formValidator.formErrors.password ? 'border-danger':''" />
          <p class="text-danger" *ngIf="formValidator?.formErrors?.password">
            {{ formValidator.formErrors.password }}
          </p>
        </div>
      </div>
    </div>

    <div class="col-lg-12 mb-0">
      <div class="d-grid">
        <button class="btn btn-primary" type="submit" [disabled]="loginForm.invalid"
          [ngClass]="{'login-submit-disabled': loginForm.invalid} ">
          {{ 'Buyer.Continue' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>

<div mat-dialog-actions>
  <div class="d-flex justify-content-between pt-2">
    <button type="button" class="btn btn-primary" (click)="close()">
      {{ 'Sellers.Dashboard.close' | translate }}
    </button>
  </div>
</div>

<recaptcha [size]="'invisible'" [hide]="false">
</recaptcha>
