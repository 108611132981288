<div class="image-field-component form-group" (dragleave)="onDragLeave($event)"
     (dragover)="onDrag($event)" (dragenter)="onDrag($event)" (drop)="onDrop($event)">
  <label class="mb-2">{{ translateFieldName(fieldObject) }}</label>
  <span *ngIf="mandatory" class="mandatory"></span>
  <div class="dottaedbord border border-dashed w-100 m-0 row">
    <div class="upload-image col" *ngIf="!imageSrc">
      <div class="mx-auto text-center d-inline-flex p-1" *ngIf="!inDrag">
        <!-- <i class="fa fa-2x m-2 fa-cloud-upload-alt text-muted"></i> -->
        <div class="m-2">
          <svg width="28" height="28" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_53_573)">
            <path d="M15.3333 15.3333L11.5 11.5L7.66663 15.3333" stroke="#515151" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.5 11.5V20.125" stroke="#515151" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19.5404 17.6237C20.4751 17.1142 21.2135 16.3078 21.6391 15.332C22.0646 14.3562 22.1531 13.2664 21.8905 12.2347C21.6279 11.2031 21.0292 10.2882 20.1889 9.63455C19.3486 8.9809 18.3146 8.6257 17.25 8.62499H16.0425C15.7525 7.50302 15.2118 6.4614 14.4612 5.57845C13.7106 4.69549 12.7697 3.99418 11.709 3.52724C10.6484 3.0603 9.49572 2.83988 8.33763 2.88255C7.17955 2.92522 6.04621 3.22987 5.02282 3.77359C3.99942 4.31732 3.1126 5.08597 2.42904 6.02176C1.74547 6.95755 1.28293 8.03613 1.07621 9.17641C0.86949 10.3167 0.923957 11.489 1.23552 12.6052C1.54708 13.7214 2.10763 14.7524 2.87502 15.6208" stroke="#515151" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.3333 15.3333L11.5 11.5L7.66663 15.3333" stroke="#515151" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_53_573">
            <rect width="23" height="23" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>

        <p  class="my-2 py-1 px-2">
          {{ 'Sellers.Products.DragDrop' | translate }}
          <a class="link-primary" (click)="fileInput.click()">{{ 'Sellers.Products.Browse' | translate }}</a>
        </p>
      </div>
      <div class="mx-auto text-center" *ngIf="inDrag">
        <i class="fa fa-2x m-2 fa-upload"></i>
        <p class="my-2">{{ 'Sellers.Products.DropFile' | translate }}</p>
      </div>
    </div>

    <div class="upload-image col text-center imguploaded" *ngIf="imageSrc">
      <div class="mx-auto text-center selected-image-container my-2">
        <img class="selected-image" [src]="sanitizer.bypassSecurityTrustUrl(imageSrc)" alt="">
      </div>
      <span class="btnploaded">
        <button (click)="onClickChangeImage($event,fileInput)" class="btn p-0 text-primary link">{{ 'Sellers.Products.Change' | translate }}</button> -
        <button (click)="clearImage()" class="btn p-0 text-warning link">{{ 'Sellers.Products.ClearImage' | translate }}</button>
      </span>
    </div>
  </div>
  <small class="text-danger"
         *ngIf="fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]">
    {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
  </small>
</div>

<input #fileInput type="file" accept="image/*" (change)="onFileInputChange($event)" style="display: none;" />
