<div class="image-field-component form-group ticket-image-field" (dragleave)="onDragLeave($event)"
     (dragover)="onDrag($event)" (dragenter)="onDrag($event)" (drop)="onDrop($event)">
  <span *ngIf="mandatory" class="mandatory"></span>
  <div class="dottaedbord border w-100 rounded row m-0">
    <div class="mx-auto text-center p-1 upload-image col"  *ngIf="!inDrag">  
        <p class="my-0 py-0 px-2" *ngIf="!selectedFile">{{ 'Sellers.Products.SelectFile' | translate }}</p>
        <p class="selected-file my-0 py-0 px-2" *ngIf="selectedFile">{{ selectedFile.name }}</p>
        <p class="my-0 py-0 px-2"><a class="link-primary" (click)="fileInput.click()">{{ 'Sellers.Products.Browse' | translate }}</a></p>
      
      <div class="mx-auto text-center" *ngIf="inDrag">
        <i class="fa fa-5x my-3 fa-upload kt-opacity-5 d-block"></i>
        <p>{{ 'Sellers.Products.DropFile' | translate }}</p>
      </div>
    </div>
  </div>
  <small class="form-error form-error-hidden" [ngClass]="{
        'form-error-hidden':
          !fieldErrors[fieldObject.fieldName] && !fieldErrors[fieldErrorName],
        'form-error-visible':
          fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]
      }">
    {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
  </small>
</div>

<input #fileInput type="file" accept="image/*" (change)="onFileInputChange($event)" style="display: none;" />
