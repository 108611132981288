<form *ngIf="formBuilderItemForm" #f="ngForm" [formGroup]="formBuilderItemForm">
  <div class="row mx-0 justify-content-center" formArrayName="ctrls">

    <div class="px-0" *ngFor="let ctrl of formFieldControls.controls; let i = index">
      <div class="cardbg-white my-3">
      <div [formGroupName]="i">
        <div [ngClass]="{'existField': dublicetIndex > -1 && dublicetIndex == i}" class="row form-group mb-2">
          <div class="col-4 col-lg-4">
            <label>{{ 'Sellers.Products.FieldLabel' | translate }}</label>
            <input class="form-control fieldLabel"
                   type="text" placeholder="{{ 'Sellers.Products.FieldLabel' | translate }}"
                   formControlName="fieldLabel"
                   (input)="onSave({})" />
            <small *ngIf="ctrl.controls.fieldLabel.errors && ctrl.controls.fieldLabel.touched" class="text-danger">
              {{ 'Sellers.Products.FieldRequired' | translate }}
            </small>
          </div>
          <div class="col-4 col-lg-4">
            <label>{{ 'Sellers.Products.FieldType' | translate }}</label>
            <select class="form-control form-select"
                    formControlName="fieldTypes"
                    [attr.disabled]="ctrl?.value?.id ? '' : null"
                    #selection
                    [ngModel] (change)="onSave()">
              <option selected disabled value="">{{ 'Sellers.Products.SelectFieldType' | translate }}</option>
              <option *ngFor="let fieldType of fieldTypesArray"
                      [value]="fieldType.id">
                {{ getFieldTypeName(fieldType) }}
              </option>
            </select>
            <small *ngIf="ctrl.controls.fieldTypes.errors && ctrl.controls.fieldTypes.touched" class="text-danger">
              {{ 'Sellers.Products.FieldRequired' | translate }}
            </small>
          </div>
          <div class="col-4 col-lg-4 px-0 d-flex justify-content-center align-items-center">
            <div>
              <a class="btn ps-0 text-danger icondelete" title="delete" (click)="deleteControl(i); onSave({})">
                <i class="fa fa-trash" style="transform: scale(1.3)"
                   title="Delete form field row"></i>
              </a>
            </div>
            <div class="pt-3 pb-2 flexrow">
              <mat-slide-toggle color="success"
                                [disabled]="!!ctrl.value.id"
                                formControlName="fieldRequired"
                                (change)="onSave({})"
                                selected="false">
                {{ 'Sellers.Products.Required' | translate }}
              </mat-slide-toggle>
            </div>
          </div>
          <div class="col-12 text-danger" *ngIf="dublicetIndex == i">
            <span *ngIf="errorType == 'basic'">{{ 'Sellers.Products.CreatedByDefault' | translate }}</span>
            <span *ngIf="errorType == 'extra'">{{ 'Sellers.Products.NameExists' | translate }}</span>
          </div>
        </div>

        <div formGroupName="selectionGroup" *ngIf="selection.value == '17' || selection.value == '19' || selection.value == '18'">
          <div formArrayName="option">
            <div *ngFor="let option of ctrl['controls'].selectionGroup['controls']
                  .option['controls']; let h = index">
              <div [formGroupName]="h" class="form-group row mb-2">
                <div class="col-8 col-lg-8">
                  <label>{{ 'Sellers.Products.Optional' | translate }}</label>
                  <input type="text" placeholder="{{ 'Sellers.Products.Optional' | translate }}"
                         class="form-control"
                         [formControl]="option.get('name')"
                         (input)="onSave({})" />
                </div>
                <div class="col-4 col-lg-4 px-0 d-flex align-items-end">
                  <a (click)="addOptionControl(i)" class="btn ps-0 text-success icondedit">
                    <i style="transform: scale(1.3)"
                       class="fas fa-plus"
                       title="add option"></i>
                  </a>
                  <a (click)="deleteOptionControl(i, h); onSave({})" class="btn ps-0 text-danger icondelete">
                    <i style="transform: scale(1.3)"
                       class="fa fa-trash"
                       title="delete option"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

    

    <div class="cardbg-white mb-3">
      <div class="text-right">
        <button class="btn btn-primary py-1" (click)="addControl()">
          {{ 'Sellers.Products.Add' | translate }}
        </button>
      </div>
  </div>

  </div>
</form>
